<template>
<div class="row row-custom" v-if="Category">
    <div class="col-md-8">
        <div class="card text-right">
        <div class="card-header">
            تفاصيل  التصنيف   
                 </div>
        <div class="card-body">
            <div class="row">   
             <table class="table">
                 <tr><td>رقم التصنيف </td><td>:</td><td>{{Category.offline_id}}</td></tr>
                  <tr><td>الاسم بالعربي </td><td>:</td><td>{{Category.name.ar}}</td></tr>
                 <tr><td>الاسم بالانجليزي</td><td>:</td><td>{{Category.name.en}}</td></tr>

                 <tr>
                     <td>صورة التصنيف</td><td>:</td><td>
                     <template v-if="Category.img">
                     <img width="100" height="100" :src="'https://pode.ly'+Category.img"/>
                     </template>
                     <template v-else>
                        <span>لايوجد</span>
                     </template>
                     </td>
                </tr>
                  <tr>
                  <td>قائمة تصنيفات الفرعية</td>
                  <td>:</td>
                  <td>
                      <ul>
                          <li v-for="(sub_category,index) in Category.SubCategories" :key="index">
                              {{sub_category.name.ar}}
                          </li>
                      </ul>
                    </td>
                  </tr>

             </table>
            </div>                      
        </div>

    </div>

    </div>
</div>

</template>

<script>
import {mapState,mapMutations} from 'vuex';
import store from '../../../store/index'
export default {
      computed:{
    ...mapState({
        Category:state=>state.admin.categories.Category.data,
        }),

      },
      methods:{
  
      },
     beforeRouteEnter(to,from,next){    
        const uuid = to.params.uuid;
        store.dispatch('admin/fetchCategory', uuid).then((response) => {
            next(true);
        }).catch(error=>{
            next(false);
        })
    },
}
</script>

<style scoped>
.row-custom{
    justify-content: center;
}

.card .card-header {
    background: #16918b;
    color: white;
    font-size: 16px;
    font-weight: 600;
    font-family: 'Tajawal';
}
.card .card-body{
    font-size: 14px;
    font-family: 'Tajawal';
    font-weight: 600;
}
</style>